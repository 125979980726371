// custom-currency.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
    transform(value: number | string, currencyCode: string = 'QAR'): string {
        const formattedValue = parseFloat(value as string).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return `${formattedValue} ${currencyCode}`;
      }
}
